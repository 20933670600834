





















import { Component, Mixins } from 'vue-property-decorator'
import ServicesForm from '@/partials/forms/ServicesForm.vue'
import axios from 'axios'

import NotificationMixin from '@/mixins/notificationMixin'

@Component({
  components: {
    ServicesForm
  }
})
export default class ServiceVistoryShow extends Mixins(NotificationMixin) {
  validToken = false

  created () {
    axios.get(`service/vistory/${this.$route.params.hash}`)
      .then(() => {
        this.validToken = true
      })
      .catch(err => {
        this.setNotification('error', err.response.data.error)
      })
  }
}
